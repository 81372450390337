import {useEffect, useState} from 'react'
import {MainTable} from '../../modules/main/MainTable'
import {getEvents} from '../../api/be'
import { FeatureTable } from '../../modules/main/FeatureTable'

export const MainPageWrapper = () => {
  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)

  const [featureEventData, setFeatureEventData] = useState<any>([])

  const getEventsData = async () => {
    const res = await getEvents()

    const sortedData = res.data.sort((a: any, b: any) => b.featured - a.featured)
    setData(sortedData)

    setFeatureEventData(sortedData.filter((e: any) => e.featured).slice(0,5))

    
    setLoading(false)
  }

  useEffect(() => {
    getEventsData().catch((err: any) => console.log(err))

    const intervalId = setInterval(() => {
      getEventsData().catch((err: any) => console.log(err))
    }, 30000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <>

      <FeatureTable data={featureEventData} loading={loading} />
      {/* login on this and set current user */}
      <MainTable data={data} loading={loading} refetch={getEventsData} />
    </>
  )
}
