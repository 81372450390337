import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'

// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-tooltip/dist/react-tooltip.css'
import 'react-bootstrap-typeahead/css/Typeahead.css';

import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {ThemeModeProvider} from './_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {GoogleOAuthProvider} from '@react-oauth/google'

setupAxios(axios)

Chart.register(...registerables)

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH || ''}>
      <MetronicI18nProvider>
        <ThemeModeProvider>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </ThemeModeProvider>
      </MetronicI18nProvider>
    </GoogleOAuthProvider>
  )
}
