import {Column} from 'react-table'
import {SelectColumnFilter, Table} from '../../components'
import {useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Tooltip} from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import moment from 'moment-timezone'
import {EventDetailModal} from './EventDetailModal'
import {CityTimezone} from '../../helpers/contants'
import { EventFormUpdateModal } from './EventFormUpdateModal'

type CustomColumn = {
  className?: string
} & Column

export const FeatureTable = ({data, loading}: {data: any[]; loading: boolean}) => {
  const [showEventDetail, setShowEventDetail] = useState<boolean>(false)
  const [showUpdateEvent, setShowUpdateEvent] = useState<boolean>(false)
  const [eventDetail, setEventDetail] = useState<any>({})

  const handleEventDetailModal = (row: any) => {
    setEventDetail(row.original)
    setShowEventDetail(true)
  }

  const columns: CustomColumn[] = [
    {
      className: 'min-w-100px',
      Header: '  ',
      Cell: (cell: any) => {
        return (
          <span className='ps-4'>
            {cell.row.original?.imageUrl && (
              <img src={cell.row.original?.imageUrl} width={100} height={100} alt='' />
            )}
          </span>
        )
      },
    },
    {
      className: 'min-w-150px',
      Header: 'Title',
      accessor: 'title',
      Cell: (cell: any) => {
        return (
          <a href={cell.row.original.pageUrl} target='_blank' rel='noreferrer'>
            {cell.row.original.title}{' '}
            {cell.row.original.featured && (
              <>
                <KTSVG
                  path='/media/icons/duotune/general/gen003.svg'
                  className='svg-icon-1 svg-icon-main'
                />
              </>
            )}
          </a>
        )
      },
    },
    {
      className: 'min-w-150px',
      Header: 'Description',
      Cell: (cell: any) => {
        return (
          <>
            <span data-tooltip-id={'detail' + cell.row.original.id}>
              {cell.row.original.description.length > 40
                ? `${cell.row.original.description.substring(0, 40)}...`
                : cell.row.original.description}
            </span>
            <Tooltip id={'detail' + cell.row.original.id} content={cell.row.original.description} />
          </>
        )
      },
    },
    {
      className: 'min-w-175px',
      Header: 'From Date',
      accessor: 'fromDate',
      Cell: (cell: any) => {
        return (
          <>
            {cell.row.original.fromDate}
          </>
        )
      },
    },
    {
      className: 'min-w-175px',
      Header: 'End Date',
      accessor: 'endDate',
      Cell: (cell: any) => {
        return (
          <>
            {cell.row.original.endDate}
          </>
        )
      },
    },
    {
      className: 'min-w-75px',
      Header: 'Format',
      accessor: 'format',
      Cell: (cell: any) => {
        return (
          <>
            <span data-tooltip-id={'detail' + cell.row.original.format + cell.row.original.id}>
              {cell.row.original.format.toLowerCase().startsWith('in-person') ? (
                <KTSVG
                  path='/media/icons/duotune/communication/com014.svg'
                  className='svg-icon-1 svg-icon-primary'
                />
              ) : cell.row.original.format.toLowerCase().startsWith('virtual') ? (
                <KTSVG
                  path='/media/icons/duotune/electronics/elc004.svg'
                  className='svg-icon-1 svg-icon-primary'
                />
              ) : (
                <KTSVG
                  path='/media/icons/duotune/abstract/abs007.svg'
                  className='svg-icon-1 svg-icon-primary'
                />
              )}
            </span>
            <Tooltip
              id={'detail' + cell.row.original.format + cell.row.original.id}
              content={cell.row.original.format}
            />
          </>
        )
      },
    },
    {
      className: 'min-w-100px',
      Header: 'City',
      accessor: 'city',
    },
    {
      className: 'min-w-100px',
      Header: 'Region',
      accessor: 'region',
    },
    {
      className: 'min-w-100px',
      Header: 'Main',
      accessor: 'mainSide',
      Cell: (cell: any) => {
        return (
          <>
            {cell.row.original.mainSide && (
              <>
                <KTSVG path='/media/icons/duotune/general/gen072.svg' className='svg-icon-1' />
              </>
            )}
          </>
        )
      },
    },
    {
      className: 'min-w-100px',
      Header: 'Recording',
      accessor: 'record',
      Cell: (cell: any) => {
        return (
          <>
            {cell.row.original.record && (
              <>
                <KTSVG path='/media/icons/duotune/general/gen072.svg' className='svg-icon-1' />
              </>
            )}
          </>
        )
      },
    },
  ]

  return (
    <>
      <Table
        title='Featured'
        columns={columns}
        data={data}
        isLoading={loading}
        pageSize={5}
        onRowClick={(row: any) => handleEventDetailModal(row)}
        globalSearch={false}
      ></Table>

      {/* <EventDetailModal
        data={eventDetail}
        setShowEventDetail={setShowEventDetail}
        showEventDetail={showEventDetail}
        setShowUpdateEvent={setShowUpdateEvent}
      />

      <EventFormUpdateModal
        data={eventDetail}
        setShowUpdateEvent={setShowUpdateEvent}
        showUpdateEvent={showUpdateEvent}
      /> */}
    </>
  )
}
