/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useTable, useSortBy, usePagination, useGlobalFilter, Row, useFilters} from 'react-table'
import {matchSorter} from 'match-sorter'
import {TableProps} from './types'
import Pagination from './pagination'
import {debounce} from 'lodash'

const GlobalFilter: React.FC<{
  preGlobalFilteredRows: Row<{}>[]
  globalFilter: any
  setGlobalFilter: (filterValue: any) => void
}> = ({preGlobalFilteredRows, globalFilter, setGlobalFilter}) => {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = debounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <div className='d-flex align-items-center'>
      <b>Search: </b>
      <input
        className='form-control bg-transparent'
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </div>
  )
}

// Define a default UI for filtering
const DefaultColumnFilter: React.FC<any> = ({
  column: {filterValue, preFilteredRows, setFilter},
}) => {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
const SelectColumnFilter: React.FC<any> = ({
  column: {filterValue, setFilter, preFilteredRows, id},
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      className='form-select-solid'
      data-kt-select2='true'
      data-placeholder='Select option'
      data-allow-clear='true'
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value=''>All</option>
      {options.map((option: any, i: number) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
  return matchSorter(rows, filterValue, {keys: [(row: any) => row.values[id]]})
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: any) => !val

const IndeterminateCheckbox = React.forwardRef(({indeterminate, ...rest}: any, ref: any) => {
  const defaultRef = React.useRef<any>(null)
  const resolvedRef = ref || defaultRef

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return <input type='checkbox' ref={resolvedRef} {...rest} />
})

const Table: React.FC<TableProps> = ({
  className,
  title,
  subtitle,
  isLoading,
  columns,
  data,
  pageSize = 10,
  onRowClick = () => {},
  initSortBy = [],
  children,
  globalSearch = true,
}) => {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: () => <></>,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: {pageIndex, globalFilter},
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize,
        sortBy: initSortBy,
        hiddenColumns: ['Thumbnail'],
      },
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <div className={`card ${className} position-relative`}>
      <div className='card-header border-0 pt-5'>
        <div>
          <h3 className='card-title align-items-start flex-column'>
            {title && <span className='card-label fw-bold fs-4 mb-1'>{title}</span>}
            {subtitle && (
              <span className='text-black text-muted mt-1 fw-semibold fs-7'>{subtitle}</span>
            )}
          </h3>
        </div>
        <div className='d-flex align-items-center'>
          {globalSearch && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}

          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column: any) => (
              <span>{column.canFilter ? column.render('Filter') : null}</span>
            ))
          )}

          <div>
            {/* <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All */}
            {allColumns.map(
              (column) =>
                column.id === 'Thumbnail' && (
                  <div key={column.id} className='form-check d-flex align-items-center'>
                    <label className='form-label me-12 mt-2'>{column.id}</label>
                    <input
                      className='form-check-input text-back w-2'
                      type='checkbox'
                      {...column.getToggleHiddenProps()}
                    />
                  </div>
                )
            )}
          </div>
        </div>
        {children}
      </div>
      {isLoading ? (
        <span
          className='w-100 translate-middle-y lh-0 my-auto d-flex align-items-center justify-content-center'
          data-kt-search-element='spinner'
        >
          <span className='spinner-border h-35px w-35px align-middle text-gray-400' />
        </span>
      ) : (
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='ReactTable table align-middle gs-0 gy-4' {...getTableProps()}>
              {/* begin::Table head */}
              <thead>
                {headerGroups.map((headerGroup) => {
                  return (
                    <tr className='fw-bold border-bottom' {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => {
                        return (
                          <th
                            className={column.className || 'min-w-125px'}
                            {...column.getHeaderProps({
                              ...column.getSortByToggleProps(),
                            })}
                          >
                            {column.render('Header')}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className='bi bi-arrow-down-short text-primary'></i>
                                ) : (
                                  <i className='bi bi-arrow-up-short text-primary'></i>
                                )
                              ) : (
                                ''
                              )}
                            </span>

                            {/* <span className='ms-2'>
                              {column.canFilter ? column.render('Filter') : null}
                            </span> */}
                          </th>
                        )
                      })}
                    </tr>
                  )
                })}
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody {...getTableBodyProps()}>
                {page.length ? (
                  page.map((row) => {
                    prepareRow(row)
                    return (
                      <tr
                        {...row.getRowProps()}
                        onClick={() => {
                          onRowClick(row)
                        }}
                      >
                        {row.cells.map((cell) => {
                          return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                      </tr>
                    )
                  })
                ) : (
                  <>
                    <tr className='fw-bolder text-center'>
                      <td colSpan={headerGroups[0].headers.length}>
                        There is currently no data available
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
          <Pagination
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
            pageOptions={pageOptions}
            pageIndex={pageIndex}
          />
        </div>
      )}

      {/* begin::Body */}
    </div>
  )
}

export {Table, SelectColumnFilter}
