import {Modal} from 'react-bootstrap'
import moment from 'moment'
import {useAuth} from '../auth'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useEffect} from 'react'
import {CityTimezone} from '../../helpers/contants'

export const EventDetailModal = ({
  data,
  showEventDetail,
  setShowEventDetail,
  setShowUpdateEvent,
}: {
  data: any
  showEventDetail: boolean
  setShowEventDetail: (showEventDetail: boolean) => void
  setShowUpdateEvent: (showUpdateEvent: boolean) => void
}) => {
  const {currentUser} = useAuth()
  const handleEditEvent = () => {
    setShowEventDetail(false)
    setShowUpdateEvent(true)
  }
  return (
    <>
      <Modal size='lg' show={showEventDetail} onHide={() => setShowEventDetail(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{data.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={data.imageUrl} className='w-100' alt='' />

          <div className='mb-10'>
            <div className='fs-4 fw-bold mb-2'></div>
          </div>

          <div className='row g-3'>
            <div className='col-2 fw-bold text-end'>Description</div>
            <div className='col-10'>{data.description}</div>

            <div className='col-2 fw-bold text-end'>From Date</div>
            <div className='col-10'>
              {data.fromDate}
            </div>

            <div className='col-2 fw-bold text-end'>End Date</div>
            <div className='col-10'>
              {data.endDate}
            </div>

            <div className='col-2 fw-bold text-end'>Format</div>
            <div className='col-10'>{data.format}</div>

            <div className='col-2 fw-bold text-end'>City</div>
            <div className='col-10'>{data.city}</div>

            <div className='col-2 fw-bold text-end'>Region</div>
            <div className='col-10'>{data.region}</div>

            <div className='col-2 fw-bold text-end'>Main Event</div>
            <div className='col-10'>
              {' '}
              {data.mainSide && (
                <KTSVG path='/media/icons/duotune/general/gen072.svg' className='svg-icon-1' />
              )}
            </div>

            <div className='col-2 fw-bold text-end'>Recording</div>
            <div className='col-10'>
              {' '}
              {data.record && (
                <KTSVG path='/media/icons/duotune/general/gen072.svg' className='svg-icon-1' />
              )}
            </div>

            <div className='col-12 fw-bold text-end'>
              <a
                href={data.pageUrl}
                target='_blank'
                rel='noreferrer'
                role='button'
                className='btn btn-primary btn-active-primary border border-active-dark'
              >
                Go To Event Page
              </a>

              {currentUser?.id === data.userId && (
                <button
                  onClick={() => handleEditEvent()}
                  className='btn btn-primary ms-2 btn-active-primary border border-active-dark'
                >
                  Update button
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
