import {FirebaseOptions, initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth'
import {getAnalytics} from 'firebase/analytics'

// const firebaseConfig: FirebaseOptions = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESS_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// }

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAl3Xp6Fxp6lVbWld5t01zOcxFT-on-8Zo',
  authDomain: 'inspiring-radar-399414.firebaseapp.com',
  projectId: 'inspiring-radar-399414',
  storageBucket: 'inspiring-radar-399414.appspot.com',
  messagingSenderId: '323954213957',
  appId: '1:323954213957:web:b0d0ae2b7791aecff7cf2c',
  measurementId: 'G-SQH6V4XLKP',
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app);

console.log("analytics ", analytics );


export const authentication = getAuth(app)
