/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {useLayout} from '../../core'
import useWindowSize from '../../../../app/hooks/useWindowSize'
import {toAbsoluteUrl} from '../../../helpers'
import {useAuth} from '../../../../app/modules/auth'
import {useGoogleLogin} from '@react-oauth/google'
import {getUserGoogleInfo, loginWithGoogle, loginWithTwitter} from '../../../../app/api/be'
import {EventFormModal} from '../../../../app/modules/main/EventFormModal'
import {Modal} from 'react-bootstrap'
import {authentication} from '../../../../firebase.config'
import {TwitterAuthProvider, signInWithPopup} from 'firebase/auth'

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {header} = config
  const headerRef: any = useRef()
  const windowSize = useWindowSize()
  const [showSubmitEvent, setShowSubmitEvent] = useState<boolean>(false)
  const {currentUser, saveAuth, setCurrentUser} = useAuth()

  const [showLoginModal, setShowLoginModal] = useState<boolean>(false)

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const googleData = await getUserGoogleInfo(codeResponse?.access_token)
      const {data} = await loginWithGoogle({
        email: googleData.email,
        name: googleData.name,
        googleId: googleData.id,
      })
      saveAuth(data)
      setCurrentUser(data.user)
      setShowSubmitEvent(true)
      setShowLoginModal(false)
    },
    onError: (error) => console.log('Login Failed:', error),
  })

  const twitterLogin = () => {
    const provider = new TwitterAuthProvider()
    signInWithPopup(authentication, provider)
      .then((twitterData) => {
        loginWithTwitter({
          email: twitterData?.user?.email || '',
          name: twitterData?.user?.displayName || '',
          twitterId: twitterData?.user?.uid || '',
        }).then(({data}) => {
          saveAuth(data)
          setCurrentUser(data.user)
          setShowSubmitEvent(true)
          setShowLoginModal(false)
        })
      })
      .catch((err) => console.log(err))
  }

  const handleSubmitEvent = () => {
    if (currentUser) {
      setShowSubmitEvent(true)
    } else {
      setShowLoginModal(true)
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  useEffect(() => {
    if (headerRef?.current?.childNodes && headerRef?.current?.childNodes?.length > 1) {
      headerRef?.current?.removeChild(headerRef.current.children[0])
    }
    if (windowSize.width) {
      if (windowSize?.width <= 1440 && headerRef?.current) {
        let width = 'unset'
        if (windowSize?.width < 1309) width = '640px'
        else if (windowSize?.width > 1309) width = '920px'
        else if (windowSize?.width === 1309) width = '800px'

        headerRef.current.style.maxWidth = width
        headerRef.current.style.overflowX = 'auto'
      } else {
        headerRef.current.style.maxWidth = 'unset'
        headerRef.current.style.overflowX = 'hidden'
      }
    }
  }, [windowSize, headerRef])

  return (
    <div
      id='kt_header'
      // className={clsx('header', classes.header.join(' '), 'align-items-stretch bg-white')}
      {...attributes.headerMenu}
    >
      <div className='container-fluid d-flex align-items-stretch justify-content-end m-0 min-w-100 my-1'>
        <div></div>
        {currentUser && (
          <div>
            {'Hello, ' + currentUser.username} |{' '}
            <span role='button' onClick={() => logout()}>
              Log out
            </span>
          </div>
        )}
      </div>
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-center justify-content-between m-0 min-w-100'
        )}
      >
        <Link to='/' className='d-flex align-items-center mr-2 me-5 mt-1'>
          <img alt='Logo Header' src={toAbsoluteUrl('/media/logos/default.png')} width={100} />
        </Link>
        <div>
          <h1>The Global Crypto Events Directory</h1>
        </div>

        {/* begin::Wrapper */}
        <div className='d-flex align-items-center justify-content-between flex-lg-grow-1'>
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav' ref={headerRef}>
              {/* <Header /> */}
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            {/* <Topbar /> */}
            <button
              className='btn btn-sm btn-primary btn-active-primary border border-active-dark text-small'
              onClick={() => handleSubmitEvent()}
            >
              Submit Event
            </button>
          </div>
        </div>

        <EventFormModal showSubmitEvent={showSubmitEvent} setShowSubmitEvent={setShowSubmitEvent} />
        {/* end::Wrapper */}

        <Modal size='sm' show={showLoginModal} onHide={() => setShowLoginModal(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className='row g-3 mb-9'>
              <div className='col-12'>
                <div
                  onClick={() => login()}
                  className='btn btn-flex btn-primary btn-active-primary btn-active-color-primary flex-center text-nowrap w-100'
                >
                  <img
                    alt='Logo'
                    src='/media/svg/brand-logos/google-icon.svg'
                    className='h-15px me-3'
                  />
                  Sign in with Google
                </div>
              </div>
              <div className='col-12'>
                <div
                  onClick={() => twitterLogin()}
                  className='btn btn-flex btn-flex btn-primary btn-active-primary btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                >
                  <img
                    alt='Logo'
                    src='/media/svg/brand-logos/twitter.svg'
                    className='theme-light-show h-15px me-3'
                  />
                  <img
                    alt='Logo'
                    src='/media/svg/brand-logos/twitter.svg'
                    className='theme-dark-show h-15px me-3'
                  />
                  Sign in with Twitter
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}
