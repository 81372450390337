import axios, {AxiosResponse} from 'axios'
import {AuthModel} from '../modules/auth'

const API_URL = process.env.REACT_APP_API_URL

export const loginWithGoogle = async (payload: {
  email: string
  name: string
  googleId: string
}): Promise<AxiosResponse<AuthModel>> => {
  const endponit = `/auth/login/google`
  const response = await axios.post(API_URL + endponit, payload)

  return response
}

export const loginWithTwitter = async (payload: {
  email: string
  name: string
  twitterId: string
}): Promise<AxiosResponse<AuthModel>> => {
  const endponit = `/auth/login/twitter`
  const response = await axios.post(API_URL + endponit, payload)

  return response
}

export const getEvents = async () => {
  const endponit = `/events/`
  const response = await axios.get(API_URL + endponit)

  return response
}

export const createEvents = async (payload: any) => {
  const endponit = `/events/`
  const response = await axios.post(API_URL + endponit, payload)

  return response
}

export const updateEvent = async (id: number ,payload: any) => {
  const endponit = `/events/${id}`
  const response = await axios.put(API_URL + endponit, payload)

  return response
}


export const getUserGoogleInfo = async (access_token: string) => {
  const response = await axios.get(
    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
        Accept: 'application/json',
      },
    }
  )

  return response.data
}
